<template>
    <div class="economy-matches">
        <div class="container-fluid">
            <card>
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="text-center">
                            <h4 v-html="$t('economy.onboarding.matches.heading', {matches: matchedContacts.length, customers: $tc('economy.customers', matchedContacts.length)})"></h4>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-offset-3">
                        <div class="text-center"><br>
                            <p class="description">{{$t('economy.onboarding.matches.explanation', {total: orderContacts.length, matches: matchedContacts.length, customersTotal: $tc('economy.customers', orderContacts.length), customersMatches: $tc('economy.customers', matchedContacts.length)})}}</p>

                            <br>

                            <router-link :to="{name: 'frontend.reporting.economy'}" class="btn btn-primary btn-fill btn-lg">
                                {{$t('economy.onboarding.matches.button')}} <i class="fa fa-angle-right"></i>
                            </router-link>
                        </div>

                        <br />
                        <br />
                    </div>

                    <div class="col-md-8 col-md-offset-2">
                        <div class="table-container">
                            <h5 class="text-muted text-center">
                                {{$t('economy.onboarding.matches.range')}}
                            </h5>

                            <br>

                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="col-lg-4 col-md-4">
                                                {{$t('economy.onboarding.table.head.name')}}
                                            </th>

                                            <th class="col-lg-4 col-md-4 text-center">
                                                {{$t('economy.onboarding.table.head.phoneNumber')}}
                                            </th>

                                            <th class="col-lg-4 col-md-4 text-center">
                                                {{$t('economy.onboarding.table.head.email')}}
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="match in matchedContacts" v-if="matchedContacts.length">
                                            <td>
                                                {{match.name}}
                                            </td>

                                            <td class="text-center">
                                                <div class="data" v-if="match.phone">
                                                    <p>{{match.phone}}</p>
                                                </div>
                                            </td>

                                            <td class="text-center">
                                                <div class="data" v-if="match.email">
                                                    <p>{{match.email}}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </card>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .table-container{
        background-color: #f7f7f7;
        border-radius: 4px;
        padding: 28px;
        border: 1px solid #e3e3e3;

        td {
            padding: 7px 3px;
        }

        p {
            padding: 0;
            margin: 0 0 -2px 0;
        }
    }

    .data {
        display: inline-block;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }
    }

    .inquiry{
        display: inline-block;
        padding-right: 32px;
    }

    .icon {
        margin-right: 10px;
        font-size: 19pt;
        display: inline-block;

        &.calls {
            color: #00438f;
            margin-top: 21px;
        }

        &.forms {
            color: #00c49f;
        }

        &.link-icon {
            font-size: 16px;
        }

        &.rotate-45 {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            display: inline-block;
        }
    }

    .description {
        font-size: 16px;
        font-weight: 200;
    }
</style>

<style lang="scss">
.economy-matches {
    span.bold {
        font-weight: 400;
    }
}
</style>

<script>
    const ContactService = require('@/services/contacts/ContactService')
    const OrderContactService = require('@/services/orders/ContactService')

    import Card from '@/app/shared/components/Card'

    export default {
      data() {
        return {
          hasLoaded: false,
          orderContacts: []
        }
      },

      mounted() {
        const options = {
          includes: ['contact', 'contact.sources']
        }

        OrderContactService.findAll(options, (response) => {
          const body = response.data;

          this.orderContacts = body.rows;
          this.hasLoaded = true
        })
      },

      computed: {
        matchedContacts() {
          return this.orderContacts.filter((orderContact) => {
            const contact = orderContact.contact

            if (!contact) {
              return false
            }

            const sources = contact.sources.map(source => source.source)

            return sources.includes('call') || sources.includes('form')
          })
        }
      },

      components: {
        Card
      }
    }
</script>
