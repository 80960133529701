<template>
	<div class="funnel-list">
		<ul class="text-center list-full-width list-funnel">
			<li>
				<template v-if="hasLoaded">
					<template v-if="hasHealthyIntegration('Google analytics')">
						{{ visits }}
					</template>
					<template v-else>
						<span class="text-muted">{{ $t('economy.funnel.noAnalytics') }}</span>
					</template>
				</template>

				<template v-else>
					<span class="mock">0000</span>
				</template>

				<br>

				<small class="text-muted">{{ $t('economy.funnel.visitsOnWebsite') }}</small>
			</li>
			<li>
				<template v-if="hasLoaded">
					<template v-if="hasHealthyIntegration(['Formstack', 'Freespee', 'WordPress forms'])">
						{{ inquiries }}
					</template>
					<template v-else>
						<span class="text-muted">{{ $t('economy.funnel.noInquiriesTracking') }}</span>
					</template>
				</template>

				<template v-else>
					<span class="mock">000</span>
				</template>

				<br>

				<small class="text-muted">
					{{ $t('economy.funnel.inquiries') }}
				</small>
				<i class="fa fa-angle-down funnel-icon"></i>
			</li>
			<li>
				<template v-if="hasLoaded">{{ ordersFromWebsite }}</template>

				<template v-else>
					<span class="mock">00</span>
				</template>

				<br>

				<small class="text-muted">{{ $t('economy.funnel.orders') }}</small>
				<i class="fa fa-angle-down funnel-icon"></i>
			</li>
		</ul>
	</div>
</template>

<style lang="scss" scoped>
.list-full-width {
	list-style: none;
	margin: 0 -30px -30px;
	padding: 0;
}

.list-funnel {
	li {
		padding: 15px 30px;
		border-top: 1px solid #e3e3e3;
		position: relative;

		p {
			margin: 0;
			padding: 0;
		}

		.funnel-icon {
			background-color: white;
			position: absolute;
			top: -7px;
			left: 50%;
			width: 9px;
			color: #e3e3e3;
			font-size: 18px;
			text-indent: -1px;
			margin-left: -4px;
		}
	}
}
</style>

<script>
import AnalyticsService from '@/services/_app/google-analytics/AnalyticsService'
import hasIntegration from '@/mixins/integrations/hasIntegration'
import {getTranslatedErrorMessage} from "@/services/response/ResponseService";

const InquiryService = require('@/services/inquiries/InquiryService')
const OrderService = require('@/services/orders/OrderService')
const moment = require('moment')

export default {
	mixins: [hasIntegration],

	props: {
		to: Object,
		from: Object
	},

	data() {
		return {
			visits: null,
			inquiries: null,
			orders: [],
			loaded: 0
		}
	},

	mounted() {
		this.load()
	},

	watch: {
		from() {
			this.load()
		},

		to() {
			this.load()
		}
	},

	computed: {
		ordersFromWebsite() {
			const ordersFromWebsite = this.orders.filter((order) => {
				const orderContact = order.orderContact

				if (!orderContact) {
					return
				}

				const contact = orderContact.contact

				if (!contact) {
					return
				}

				const sources = contact.sources.map(source => source.source)

				return (sources.includes('call') || sources.includes('form'))
			})

			return ordersFromWebsite.length
		},

		hasLoaded() {
			return this.loaded >= 3
		}
	},

	methods: {
		load() {
			this.loaded = 0

			this.loadInquires()
			this.loadOrders()
			this.loadVisits()
		},

		async loadVisits() {
			if (!this.hasHealthyIntegration('Google analytics')) {
				this.loaded++
				return
			}

			let body = await AnalyticsService.sessions(this.from, this.to);
			if (body?.success === false) {
				this.$swal({
					type: 'error',
					text: getTranslatedErrorMessage(body.errorCode)
				});
				body = body?.errorMsg;
			}

			this.visits = body.total
			this.loaded++
		},

		loadInquires() {
			if (!this.hasHealthyIntegration(['Formstack', 'Freespee', 'WordPress forms'])) {
				this.loaded++
				return
			}

			const to = moment(this.to)
			to.add(1, 'day')

			const options = {
				limit: 1,
				filter_groups: [
					{
						filters: [
							{
								key: 'date',
								value: this.from.format('YYYY-MM-DD'),
								operator: 'gte'
							},
							{
								key: 'date',
								value: to.format('YYYY-MM-DD'),
								operator: 'lte'
							}
						]
					}
				]
			}

			InquiryService.findAll(options, (response) => {
				const body = response.data
				this.inquiries = body.total

				this.loaded++
			})
		},

		loadOrders() {
			const to = moment(this.to)
			to.add(1, 'day')

			const options = {
				includes: ['orderContact', 'orderContact.contact', 'contact.sources'],
				filter_groups: [
					{
						filters: [
							{
								key: 'date',
								value: this.from.format('YYYY-MM-DD'),
								operator: 'gte'
							},
							{
								key: 'date',
								value: to.format('YYYY-MM-DD'),
								operator: 'lte'
							}
						]
					}
				]
			}

			OrderService.findAll(options, (response) => {
				const body = response.data
				const rows = body.rows

				this.orders = rows

				this.loaded++
			})
		}
	}
}
</script>
