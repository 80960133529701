<template>
    <div class="element">
        <loader v-if="!hasLoaded"></loader>

        <div class="chart-wrapper">
            <div class="chart"></div>

            <div class="footer">
                <range :from="from" :to="to"></range>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .element {
        position: relative;
    }

    .chart {
        min-height: 350px;
        margin-top: 30px;
    }

    .loader {
        position: absolute;
        top: 40%;
        width: 100%;
    }

    .footer {
        overflow: hidden;
        height: 45px;

    hr {
        margin: 8px 0;
    }
    }
</style>

<script>
  import Loader from '@/app/shared/components/Loader'
  import Range from '@/app/layout/components/Range'

  const HighCharts = require('highcharts')
  const OrderService = require('@/services/orders/OrderService')

  const moment = require('moment')

  export default {
    props: {
      to: Object,
      from: Object,
      showMonths: Number,
    },

    data() {
      return {
        hasLoaded: false,
        orders: [],
        series: [],
        categories: [],
        totalTurnoverData: [],
        websiteTurnoverData: [],
        estimatedTurnoverData: [],
        websiteShare: 0,
        chart: null,
      }
    },

    mounted() {
      this.load();
    },

    methods: {
      load() {
        const options = {
          includes: ['orderContact', 'orderContact.contact', 'contact.sources', 'customer'],
          filter_groups: [
            {
              filters: [
                {
                  key: 'date',
                  value: moment().subtract(this.showMonths, 'months').format('YYYY-MM-DD'),
                  operator: 'gte',
                },
                {
                  key: 'date',
                  value: moment().add(1, 'day').format('YYYY-MM-DD'),
                  operator: 'lte',
                }
              ]
            }
          ]
        };

        OrderService.findAll(options, (response) => {
          const body = response.data;
          this.orders = body.rows;

          this.websiteShare = this.calculateWebsiteShare();

          this.addDataPoints();
          this.createSeries();
          this.render();

          this.hasLoaded = true;
        });
      },

      addDataPoints() {
        const startOfMonth = moment().startOf('month').subtract(this.showMonths - 1, 'months');
        const endOfMonth = moment().endOf('month').subtract(this.showMonths - 1, 'months');
        const today = moment();

        while (startOfMonth.isBefore(today)) {
          let totalTurnover = 0;
          let websiteTurnover = 0;
          let noContactInfoTurover = 0;

          this.orders.forEach((order) => {
            const date = moment(order.date.date);

            if (date.isBefore(startOfMonth) || date.isAfter(endOfMonth)) {
              return;
            }

            // Total amount

            totalTurnover += order.amount;

            // Check if the order is linked to a website contact

            const orderContact = order.orderContact

            if (!orderContact) {
              return
            }

            const contact = orderContact.contact

            if (!contact) {
              return
            }

            const sources = contact.sources.map(source => source.source);

            const customer = order.customer

            if (!customer) {
              return
            }

            const startDate = customer.startDate

            if (!startDate) {
              return
            }

            const customerStartDate = moment(startDate.date);

            if (date.isAfter(customerStartDate) && (sources.includes('call') || sources.includes('form'))) {
              websiteTurnover += order.amount;
            }

            if (date.isAfter(customerStartDate) && orderContact.phone == null) {
              noContactInfoTurover += order.amount;
            }
          });

          // Add category
          const category = startOfMonth.format('MMMM \'YY');
          this.categories.push(category);

          // Add data points
          this.totalTurnoverData.push(parseInt(totalTurnover));
          this.websiteTurnoverData.push(parseInt(websiteTurnover));
          this.estimatedTurnoverData.push(parseInt(noContactInfoTurover * this.websiteShare));

          startOfMonth.add(1, 'month');
          endOfMonth.add(1, 'month');
        }
      },

      createSeries() {
        this.series.push({
          type: 'column',
          name: $t('economy.chart.label1'),
          data: this.estimatedTurnoverData,
          stack: 'turnover'
        });

        this.series.push({
          type: 'column',
          name: $t('economy.chart.label2'),
          data: this.websiteTurnoverData,
          stack: 'turnover'
        });

        this.series.push({
          type: 'column',
          name: $t('economy.chart.label3'),
          data: this.totalTurnoverData,
          stack: 'total'
        });
      },

      render() {
        let element = this.$el.querySelector('.chart');

        this.chart = new HighCharts.Chart(element, {
          title: {
            text: ' '
          },
          xAxis: {
            categories: this.categories,
          },
          yAxis: {
            title: {
              text: 'Kroner'
            }
          },
          colors: ['#baddff', '#7cb5ec', '#ffac62', '#FE9D45', '#8085e9',
            '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
          labels: {
            items: [{
              style: {
                left: '50px',
                top: '18px',
                color: (HighCharts.theme && HighCharts.theme.textColor) || 'black'
              }
            }]
          },
          plotOptions: {
            column: {
              stacking: 'normal'
            }
          },
          series: this.series,
          credits: false
        })
      },

      /**
       * We take the customers who has a phone number. That is the customers we can track.
       * The function calculates the turnover via website vs. turnover from all.
       * This gives us an estimate of how many customers that drops by the website before
       * they place an order.
       *
       * @returns {number}
       */
      calculateWebsiteShare() {
        let websiteTurnover = 0;
        let totalTurnover = 0;

        this.orders.forEach((order) => {
          const orderContact = order.orderContact

          if (!orderContact) {
            return
          }

          const contact = orderContact.contact

          if (!contact) {
            return
          }

          const phone = contact.phone
          const sources = contact.sources.map(source => source.source)

          if (!order.customer.startDate) {
            return;
          }

          const customerStartDate = moment(order.customer.startDate.date)

          const date = moment(order.date.date)

          if (date.isBefore(customerStartDate)) {
            return;
          }

          totalTurnover += order.amount;

          if (phone == null) {
            return;
          }

          if (sources.includes('call') || sources.includes('form')) {
            websiteTurnover += order.amount;
          }
        }, this);

        return websiteTurnover / totalTurnover;
      }
    },

    components: {
      Loader,
      Range
    }
  }
</script>
