const BaseService = require('@/services/BaseService')

const namespace = 'orders'

const find = (id, options, cb, errorCallback) => {
  BaseService.get(namespace + '/' + id, options, cb, errorCallback);
}

const findAll = (options, cb, errorCallback) => {
  BaseService.get(namespace, options, cb, errorCallback);
}

const count = (options, cb, errorCb) => {
  BaseService.get(namespace + '/count', options, cb, errorCb)
}

const totalAmount = (options, cb, errorCb) => {
  BaseService.get(namespace + '/total-amount', options, cb, errorCb)
}

export {
  find,
  findAll,
  count,
  totalAmount
}
