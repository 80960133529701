<template>
    <div class="container-fluid orders-integrating">
        <card>
            <h1 class="lead text-center">
                <template v-if="!hasLoaded">{{$t('economy.connecting', {financialSystem: service})}}</template>
                <template v-else-if="!error">{{$t('economy.success')}}</template>
                <template v-else>{{$t('economy.error')}}</template>
            </h1>

            <div class="text-center connecting">
                <div class="from">
                    <img src="/images/spotonlive-logo.svg" class="logo">
                </div>

                <div class="arrow">
                    <span class="pe-7s-angle-left"></span>
                </div>

                <div>
                    <img v-if="service == 'economic'" src="/images/economy/economic_dark.png" />

                    <img v-else-if="service == 'dinero'" src="/images/economy/dinero_dark.png" />

                    <img v-else-if="service == 'ordrestyring'" src="/images/economy/ordrestyring_dark.png" />

                    <img v-else-if="service == 'billysbilling'" src="/images/economy/billysbilling_dark.png" />
                </div>
            </div>

            <div class="row" v-if="!hasLoaded">
                <div class="col-md-8 col-md-offset-2">
                    <p class="text-center connecting-description" v-html="$t('economy.description', {financialSystem: service, email: identity.email})"></p>
                </div>
            </div>

            <template v-if="hasLoaded">
                <div class="text-center success-button" v-if="!error">
                    <router-link :to="{name: 'frontend.reporting.economy.onboarding.matches'}" class="btn btn-primary btn-fill btn-lg">
                        {{$t('economy.link')}} <i class="fa fa-angle-right"></i>
                    </router-link>
                </div>
            </template>
            <br><br>
        </card>
    </div>
</template>

<style lang="scss" scoped>
    .orders-integrating {
        h1 {
            font-size: 20pt;
        }

        h2 {
            font-size: 14pt;
        }

        .connecting {
            display: table;
            margin: 40px auto;

            .logo {
                width: 280px;
            }

            > div {
                width: 200px;
                display: table-cell;
                vertical-align: middle;

                &.arrow {
                    width: 200px;
                    text-align: center;
                    font-size: 50pt;

                    span {
                        vertical-align: middle;
                    }
                }

                img {
                    display: inline-block;
                    max-width: 200px;
                    max-height: 60px;
                }
            }
        }

        .icons {
            .icon {
                display: inline-block;
                font-size: 45pt;
                margin-bottom: 10px;

                &.loader {
                    font-size: 30pt;
                }
            }
        }

        .connecting-description {
            margin-top: 30px;
        }

        .success-button {
            margin-top: 50px;
        }
    }
</style>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import moment from 'moment'
    const IntegrationService = require('@/services/orders/IntegrationService')

    export default {
        data() {
            return {
                hasLoaded: false,
                error: false
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

            service() {
                return this.$route.params.service
            }
        },

        mounted() {
            switch (this.service) {
                case 'economic':
                    this.connectEconomic();
                    break;

                case 'dinero':
                    break;

                default:
                    this.error = true;
                    break;
            }
        },

        methods: {
          ...mapActions('identity', {
            reloadIdentity: 'loadIdentity'
          }),


          connectEconomic() {
                var query = this.$route.query;

                if (!query.token) {
                    this.error = true;
                    return;
                }

                IntegrationService.integrate('economic', {data: query.token}, () => {
                    MixpanelService.track('Integration - Create', {'Integration type': 'Economic'})

                    this.reloadIdentity();
                }, () => {
                    this.error = true;
                    this.hasLoaded = true;
                })
            }
        }
    }
</script>
