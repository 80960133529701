<template>
    <div class="progress-bobble">
        <div class="fill" v-bind:style="{top: top, height: height}"></div>
        <img class="mask" src="/images/economy/bobble_mask.png">
    </div>
</template>

<style lang="scss" scoped>
    .progress-bobble {
        position: relative;

        .fill {
            position: absolute;
            top: 65px;
            left: 0;
            height: 0px;
            width: 65px;
            background-color: #4096ee;
            overflow:hidden;
        }

        .mask {
            display: block;
            height: 65px;
            left: 0;
            position: absolute;
            top: 0;
            width: 65px;
            overflow:hidden;
        }
    }
</style>

<script>
    export default {
      props: {
        fill: Number,
      },
      computed: {
        top() {
          let pixels = (this.fill/100) * 65

          return (65-pixels) + 'px'
        },

        height() {
          let pixels = (this.fill/100) * 65

          return pixels + 'px'
        }
      },
    }
</script>
