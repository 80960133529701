<template>
    <div class="banner hidden-sm hidden-xs">
        <div class="row">
            <div class="col-md-12 text-center visible-md">
                <h3>{{$t('economy.estimatePage.moreBanner.news')}}</h3>
            </div>

            <div class="col-md-6 visible-md visible-lg">
                <img class="img-responsive" src="/images/economy/economy-estimate-banner.png">
            </div>

            <div class="col-lg-6 col-md-6">
                <h3 class="hidden-md">{{$t('economy.estimatePage.moreBanner.news')}}</h3>
                <p v-html="$t('economy.estimatePage.moreBanner.text')"></p>
                <a class="btn btn-inverse btn-fill" @click="integrate">{{$t('economy.estimatePage.moreBanner.buttonText')}} <i class="fa fa-angle-right"></i></a>
            </div>
        </div>

        <!-- Provider modal -->
        <modal ref="providerModal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="lead text-center">
                {{$t('economy.landingpage.chooseSystem')}}
            </div>

            <div class="logo" @click="connect('economic')">
                <img src="/images/economy/economic_dark.png" />
            </div>

            <div class="logo" @click="navigateTo('dinero')">
                <img src="/images/economy/dinero_dark.png" />
            </div>

            <div class="text-center">
                <div style="padding: 0 15px">
                    <br>
                    <br>

                    <span v-if="!isFreemium" v-html="$t('economy.landingpage.needHelp', {phoneNumber: $t('phoneNumber')})"></span>
                </div>
            </div>
        </modal>

        <!-- Integration modal -->
        <modal ref="integrationModal">
            <div class="modal-body integrate">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

                <div class="text-center">
                    <img class="header-logo" v-if="integration == 'ordrestyring'" src="/images/economy/ordrestyring_dark.png" />
                    <img class="header-logo" v-if="integration == 'billysbilling'" src="/images/economy/billysbilling_dark.png" />
                    <img class="header-logo" v-if="integration == 'dinero'" src="/images/economy/dinero_dark.png" />
                </div>

                <div class="form-group">
                    <p class="text-center" v-if="!isFreemium" v-html="$t('economy.landingpage.needHelp', {phoneNumber: $t('phoneNumber')})"></p>

                    <!-- Alert -->
                    <div v-if="hasFailed" class="alert alert-danger" role="alert" v-html="$t('economy.landingpage.invalidKey', {phoneNumber: $t('phoneNumber')})"></div>

                    <template>
                        <label for="id">
                            {{$t('economy.landingpage.key')}}
                        </label>

                        <input v-model="key" type="text" class="form-control" id="id" placeholder="" :class="{danger: hasFailed}">
                    </template>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn btn-default" data-dismiss="modal" >{{$t('economy.landingpage.cancel')}}</button>

                <button class="btn btn-success" @click="connect('economic')">
                    <span v-if="submitting" class="fa fa-spin fa-spinner fa-fw"></span>
                    <span v-if="!submitting" class="fa fa-check fa-fw"></span>&nbsp;

                    {{$t('economy.landingpage.connect')}}
                </button>
            </div>
        </modal>
    </div>
</template>

<style lang="scss" scoped>
    .banner {
        color: white;
        margin-bottom: 30px;
        border-radius: 4px;
        padding: 30px;

        background: #1f5da0; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(#2c72bf , #1f5da0); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(#2c72bf, #1f5da0); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(#2c72bf, #1f5da0); /* For Firefox 3.6 to 15 */
        background: linear-gradient(#2c72bf , #1f5da0); /* Standard syntax */

        img {
            padding: 0 30px;
        }

        @media (min-width: 1700px) {
            img {
                max-height: 230px;
                margin: 0 auto;
            }
        }

        @media (min-width: 993px) and (max-width: 1375px) {
            img {
                margin-top: 30px;
                padding: 0;
            }
        }

        h3 {
            font-size: 24px;
            font-weight: 400;
        }

        p {
            margin-top: 15px;
        }

        a.btn {
            margin-top: 15px;
            margin-bottom: 20px;
            color: #184578;
            padding-left: 25px;
        }
    }

    .modal {
        color: #000;

        .modal-body {
            text-align: left;
        }

        .lead {
            font-size: 20px;
            margin: 25px 0 20px 0;
        }

        .modal-body.logo-container {
            padding: 0;
            text-align: center;
        }

        .logo {
            text-align: center;
            height: 95px;
            border-radius: 4px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }

            &:before {
                content: ' ';
                display: inline-block;
                vertical-align: middle;
                height: 100%;
            }

            img {
                cursor: pointer;
                max-width: 150px;
                padding: 20px 0;
                display: inline-block;
                vertical-align: middle;
                max-height: 80px;
            }
        }
    }
</style>

<script>
    import {mapActions} from 'vuex'
    import Modal from '@/app/layout/components/Modal'

    import connectEconomic from '@/mixins/integrations/connectEconomic'
    import organization from '@/mixins/customer/organization'

    export default {
        mixins: [connectEconomic, organization],

        data() {
            return {
                key: null,
                integration: null,
                submitting: false,
                hasFailed: false,
            }
        },

        watch: {
            key() {
                if (!this.key) {
                    return
                }

                this.hasFailed = false
            }
        },

        mounted() {
            jQuery('.bind-popover').popover()
        },

        methods: {
          integrate() {
                jQuery(this.$refs.providerModal.$el).modal('show')
            },

            connect(service) {
                this.closeModal()

                this.key = null
                this.integration = null
                this.submitting = false

                switch (service) {
                    case 'economic':
                        this.connectEconomic()
                        break
                }
            },

            navigateTo(service) {
              jQuery(this.$refs.providerModal.$el).modal('hide')

              switch (service) {
                case 'dinero':
                  this.$router.push({name: 'frontend.settings.integrations.dinero.setup.startPage'})
              }
            },

            closeModal() {
                jQuery(this.$refs.integrationModal.$el).modal('hide')
            },
        },
        components: {
            Modal
        }
    }
</script>
