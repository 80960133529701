 <template>
     <div class="alert" v-if="show" :class="[typeClass, sizeClass]">
         <slot></slot>

         <a class="link-close" @click="remove">
             <span class="fa fa-close"></span>
         </a>
     </div>
 </template>

 <style lang="scss" scoped>
     .alert {
         position: relative;

        a.link-close {
            color: initial;
            position: absolute;
            top: 15px;
            right: 15px;
            opacity: 0.25;
            cursor: pointer;
            font-size: 16px;

            &:hover {
                opacity: 0.5;
                cursor: pointer;
            }
         }
     }
 </style>

 <script>
     export default {
       props: {
         alertId: {
           type: String,
           required: true
         },
         type: {
           type: String,
           default: 'primary'
         },
         size: {
           type: String,
           default: 'lg'
         }
       },

       data() {
         return {
           show: true
         }
       },

       computed: {
         typeClass() {
           return 'alert-' + this.type
         },

         sizeClass() {
           return 'alert-' + this.size
         },

         localStorageId() {
           return 'alert-' + this.alertId
         }
       },

       mounted() {
         this.show = (this.$localStorage.get(this.localStorageId) != 'closed') ? true : false
       },

       methods: {
         remove() {
           this.$localStorage.set(this.localStorageId, 'closed')
           this.show = false
         }
       }
     }
 </script>