<template>
    <div class="active-economy-integration-page">
        <div class="container-fluid" v-if="hasData">
            <transition name="slide-fade">
                <alert :alertId="'economy-message'" v-if="contactInformationCompleteness !== null && contactInformationCompleteness !== 100">
                    <span v-html="$t('economy.alert', {contactInformationCompleteness: contactInformationCompleteness, inverseContactInformationCompleteness: 100 - contactInformationCompleteness})"></span>
                </alert>
            </transition>

            <div class="row row-lg-flex">
                <div class="col-lg-4 col-lg-flex col-md-12">
                    <card class="center-content">
                        <info-box icon="pe-7s-cash" :number="turnover"
                                  :unit="$t('economy.turnover')" :hasLoaded="hasLoadedTurnover"></info-box>
                    </card>
                </div>

                <div class="col-lg-4 col-lg-flex col-md-12">
                    <card class="center-content">
                        <info-box icon="pe-7s-user" :number="averageTurnover"
                                  :unit="$t('economy.average')" :hasLoaded="hasLoadedTurnover"></info-box>
                    </card>
                </div>

                <div class="col-lg-4 col-lg-flex col-md-12">
                    <card>
                        <div class="pull-right progress-chart">
                            <progress-bobble :fill="contactInformationCompleteness"></progress-bobble>
                        </div>

                        <div class="progress-description">
                            <div v-html="$t('economy.dataMessage', {percentage: contactInformationCompleteness, economySystem: 'E-conomic'})"></div>
                        </div>
                    </card>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <card :headline="$t('economy.chart.headline')" :description="$t('economy.chart.description', {showMonths: 12})">
                        <turnover-chart :from="from" :to="to" :showMonths="12"></turnover-chart>
                    </card>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <card :headline="$t('economy.newCustomers.headline')" :description="$t('economy.newCustomers.description')" icon="pe-7s-users">
                        <newest-customers-list></newest-customers-list>
                    </card>
                </div>


                <div class="col-lg-4 col-md-6">
                    <card :headline="$t('economy.topCustomers.headline')" :description="$t('economy.topCustomers.description')" icon="pe-7s-like">
                        <top-customers-list></top-customers-list>
                    </card>
                </div>

                <div class="col-lg-4 col-md-12">
                    <card :headline="$t('economy.funnel.headline')" :description="$t('economy.funnel.description')" icon="pe-7s-filter">
                        <funnel-list :from="from" :to="to"></funnel-list>
                    </card>
                </div>
            </div>
        </div>

        <waiting-for-data v-else></waiting-for-data>
    </div>
</template>

<style lang="scss" scoped>
    .progress-chart {
        margin-left: 15px;
        width: 65px;
        height: 65px;
    }

    .progress-description {
        min-height: 65px;
        display: flex;
        align-items: center;
    }
</style>

<style lang="scss">
    @import '~@/assets/scss/_vars.scss';

    .active-economy-integration-page {
        span.bold {
            font-weight: 600;
        }

        .progress-description > div {
            display: block;
        }

        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            .info-box {
                min-height: 155px;
            }

            .picker > .panel {
                min-height: 125px;
                padding-top: 18px;
            }
        }
    }
</style>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import Banner from '@/app/onboarding/components/Banner'
  import InfoBox from '@/app/layout/components/InfoBox'
  import Card from '@/app/shared/components/Card'
  import Interval from '@/app/datepicker/components/Interval'
  import TurnoverChart from '@/app/economy/components/TurnoverChart.vue'
  import NewestCustomersList from '@/app/economy/components/NewestCustomersList.vue'
  import TopCustomersList from '@/app/economy/components/TopCustomersList.vue'
  import FunnelList from '@/app/economy/components/FunnelList.vue'
  import Alert from '@/app/shared/global/warnings/Alert.vue'
  import ProgressBobble from '@/app/economy/components/ProgressBobble.vue'
  import WaitingForData from '@/app/economy/components/WaitingForData'
  import datepickerMixin from '@/app/datepicker/datepicker.mixin'

  const OrderService = require('@/services/orders/OrderService')
  const ContactService = require('@/services/orders/ContactService')

  const moment = require('moment')

  export default {
    mixins: [datepickerMixin],
    data () {
      return {
        orders: [],
        contactsTotal: 0,
        contactsWithPhoneNumber: 0,
        loaded: 0,
        hasLoadedTurnover: false
      }
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

      ordersFromWebsite () {
        return this.orders.filter((order) => {
          const orderContact = order.orderContact

          if (!orderContact) {
            return
          }

          const contact = orderContact.contact

          if (!contact) {
            return
          }

          const sources = contact.sources.map(source => source.source)

          return (sources.includes('call') || sources.includes('form'))
        })
      },

      hasLoaded () {
        return this.loaded >= 2
      },

      turnover () {
        let turnover = 0

        this.ordersFromWebsite.forEach((order) => {
          turnover += order.amount
        })

        return parseInt(turnover)
      },

      averageTurnover () {
        const numberOfOrders = this.ordersFromWebsite.length

        if (!numberOfOrders) {
          return 0
        }

        return parseInt(this.turnover / numberOfOrders)
      },

      contactInformationCompleteness () {
        if (this.contactsTotal == 0 || this.contactsWithPhoneNumber == 0) {
          return null
        }

        return Math.round(this.contactsWithPhoneNumber / this.contactsTotal * 100)
      },

      hasData () {
        const economyIntegrations = ['Order: E-conomic', 'Order: Dinero']

        const customer = this.customer

        if (!customer || !customer.integrations) {
          return false
        }

        const integrations = customer.integrations

        for (let key in integrations) {
          const integration = integrations[key]
          const name = integration.service.name

          if (!economyIntegrations.includes(name)) {
            continue
          }

          if (integration.updated === null) {
            continue
          }

          return true
        }

        return false
      }
    },

    watch: {
      hasData () {
        if (!this.hasData) {
          return
        }

        this.load()
        this.loadContactsTotal()
        this.loadContactsWithPhoneNumber()
      }
    },

    mounted () {
      if (!this.hasData) {
        this.reloadIdentity()
        return
      }

      this.load()
      this.loadContactsTotal()
      this.loadContactsWithPhoneNumber()
    },

    methods: {
      ...mapActions('identity', {
        reloadIdentity: 'loadIdentity'
      }),

      load(){
        this.loadTurnover()
      },

      loadTurnover () {
        this.hasLoadedTurnover = false
        this.orders = []

        const to = moment(to)
        to.add(1, 'day')

        const options = {
          includes: ['orderContact', 'orderContact.contact', 'contact.sources'],
          filter_groups: [
            {
              filters: [
                {
                  key: 'date',
                  value: this.from.format('YYYY-MM-DD'),
                  operator: 'gte'
                },
                {
                  key: 'date',
                  value: to.format('YYYY-MM-DD'),
                  operator: 'lt'
                }
              ]
            }
          ]
        }

        OrderService.findAll(options, (response) => {
          const body = response.data

          this.orders = body.rows

          this.hasLoadedTurnover = true
        })
      },

      loadContactsTotal () {
        ContactService.count({}, (response) => {
          const body = response.data

          this.contactsTotal = body.count

          this.loaded++
        })
      },

      loadContactsWithPhoneNumber () {
        const options = {
          filter_groups: [
            {
              filters: [
                {
                  key: 'phone',
                  operator: 'eq',
                  value: 'NULL',
                  not: true
                }
              ]
            }
          ]
        }

        ContactService.count(options, (response) => {
          const body = response.data

          this.contactsWithPhoneNumber = body.count
          this.loaded++
        })
      }
    },
    components: {
      Banner,
      InfoBox,
      Card,
      Interval,
      TurnoverChart,
      Alert,
      NewestCustomersList,
      TopCustomersList,
      FunnelList,
      ProgressBobble,
      WaitingForData
    }
  }
</script>

