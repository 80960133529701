const BaseService = require('@/services/BaseService')

const namespace = 'orders/contacts'

const find = (id, options, cb) => {
  BaseService.get(namespace + '/' + id, options, cb);
}

const findAll = (options, cb) => {
  BaseService.get(namespace, options, cb);
}

const count = (options, cb) => {
  BaseService.get(namespace + '/count', options, cb);
}

const contactsWithTotalAmountSpent = (options, cb) => {
  BaseService.get(namespace + '/contactsWithTotalAmountSpent', options, cb);
}

export {
  find,
  findAll,
  count,
  contactsWithTotalAmountSpent,
}
