<template>
    <div class="estimate-info">
        <div class="text-center" v-if="collapsed">
            <br>

            <p class="formula">
                {{$t('economy.estimatePage.estimateInfo.formula')}}
            </p>

            <br>

            <a @click="collapsed = false">{{$t('economy.estimatePage.estimateInfo.readMore')}}</a>
        </div>

        <div class="long-text" v-else>
            {{$t('economy.estimatePage.estimateInfo.text1')}}

            <br>

            <br>

            <p class="formula text-center">
                <strong>{{$t('economy.estimatePage.estimateInfo.formula')}}</strong>
            </p>

            <br>

            {{$t('economy.estimatePage.estimateInfo.text2')}}

            <br>

            <br>

            {{$t('economy.estimatePage.estimateInfo.text3')}}

        </div>
    </div>
</template>

<style lang="scss" scoped>
    .estimate-info {
        .formula {
            font-family: 'Crimson Text', serif;
            font-size: 16pt;
            margin: 0;
        }

        .long-text {
            line-height: 200%;
        }
    }
</style>

<script>
    export default {
        data() {
            return {
                collapsed: true
            }
        }
    }
</script>
