<template>
    <div class="container-fluid orders-integrating">
        <card>
            <h1 class="lead text-center">
                {{$t('economy.waitingForData.headline', {email: identity.email})}}
            </h1>

            <div class="row" v-if="!hasLoaded">
                <div class="col-md-8 col-md-offset-2">
                    <p class="text-center connecting-description" v-html="$t('economy.waitingForData.description', {email: identity.email})"></p>
                </div>
            </div>


            <img class="center-block img-responsive" src="/images/economy/screenshot-in-chrome-frame.png">
        </card>
    </div>
</template>

<style lang="scss" scoped>
    .orders-integrating {
        h1 {
            font-size: 20pt;
        }

        h2 {
            font-size: 14pt;
        }

        .connecting {
            display: table;
            margin: 40px auto;

            > div {
                width: 200px;
                display: table-cell;
                vertical-align: middle;

                &.arrow {
                    width: 200px;
                    text-align: center;
                    font-size: 50pt;

                    span {
                        vertical-align: middle;
                    }
                }

                img {
                    display: inline-block;
                    max-width: 200px;
                    max-height: 60px;
                }
            }
        }

        .icons {
            .icon {
                display: inline-block;
                font-size: 45pt;
                margin-bottom: 10px;

                &.loader {
                    font-size: 30pt;
                }
            }
        }

        .connecting-description {
            margin-top: 30px;
        }

        .success-button {
            margin-top: 50px;
        }
    }
</style>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import Loader from '@/app/shared/components/Loader.vue'
  import Card from '@/app/shared/components/Card.vue'

  const moment = require('moment')

  export default {
    data () {
      return {
        hasLoaded: false,
        error: false
      }
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),

      service () {
        return this.$route.params.service
      }
    },

    mounted () {
      switch (this.service) {
        case 'economic':
          this.connectEconomic()
          break

        default:
          this.error = true
          break
      }
    },

    methods: {
      ...mapActions('identity', {
        reloadIdentity: 'loadIdentity'
      }),
      connectEconomic () {
        var query = this.$route.query

        if (!query.token) {
          this.error = true
          return
        }

        this.$http.post('orders/integrate/economic', { data: query.token }).then((response) => {
          this.reloadIdentity()
        }, (response) => {
          this.error = true
          this.hasLoaded = true
        })
      },

      redirect () {
        this.$router.push({
          name: 'frontend.reporting.economy.onboarding.matches'
        })
      }
    },

    components: {
      Loader,
      Card
    }
  }
</script>
