<template>
    <div class="element">
        <loader v-if="!hasLoaded"></loader>

        <div class="chart-wrapper">
            <div class="chart"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .element {
        position: relative;
    }

    .chart {
        min-height: 350px;
        margin-top: 30px;
    }

    .loader {
        position: absolute;
        top: 40%;
        width: 100%;
    }

    .footer {
        overflow: hidden;
        height: 45px;

    hr {
        margin: 8px 0;
    }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import Range from '@/app/layout/components/Range'

    const HighCharts = require('highcharts')
    const InquiryService = require('@/services/inquiries/InquiryService')

    const moment = require('moment')

    export default {
        props: {
            conversionRate: {
                type: Number,
                default: 0
            },

            turnoverPrOrder: {
                type: Number,
                default: 0
            }
        },

        data() {
            return {
                showMonths: 12,
                hasLoaded: false,
                chart: null,
                loaded: 0,
                inquiries: [],
                categories: [],
                data: []
            }
        },

        mounted() {
            this.load();
        },

        watch: {
            loaded() {
                if (this.loaded == this.showMonths) {
                    this.hasLoaded = true

                    this.render()

                    this.$emit('hasLoaded')
                }
            },

            conversionRate() {
                this.render()
            },

            turnoverPrOrder() {
                this.render()
            }
        },

        methods: {
            load() {
                this.fetchData()
            },

            render() {
                this.data = []

                this.createDataPoints()

                let element = this.$el.querySelector('.chart');

                this.chart = new HighCharts.chart(element, {
                    chart: {
                        type: 'column'
                    },
                    title: false,
                    xAxis: {
                        categories: this.categories.reverse()
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: $t('economy.estimatePage.turnoverChart.y')
                        }
                    },
                    tooltip: {
                        pointFormat: '<span>{series.name}</span>: <strong>{point.y}</strong> kr.<br/> <small class="text-muted">{point.inquiries} ' + $t("economy.estimatePage.turnoverChart.inquiries") + '</small>',
                        shared: true
                    },
                    series: [{
                        name: $t('economy.estimatePage.turnoverChart.categoryName'),
                        data: this.data.reverse()
                    }],
                    credits: false
                });
            },

            createDataPoints() {
                this.inquiries.forEach((inquiryCount) => {

                    const turnover = Math.round(inquiryCount * (this.conversionRate / 100) * this.turnoverPrOrder)

                    this.data.push({
                        y: turnover,
                        inquiries: inquiryCount
                    })
                })
            },

            fetchData() {
                let startOfMonth = moment().startOf('month');
                let endOfMonth = moment().endOf('month');

                for (let i = 0; i < this.showMonths; i++) {
                    this.inquiries[i] = 0
                    this.categories[i] = this.capitalize(startOfMonth.format('MMMM'))

                    const options = {
                        limit: 0,
                        filter_groups: [
                            {
                                filters: [
                                    {
                                        key: 'date',
                                        value: startOfMonth.format('YYYY-MM-DD'),
                                        operator: 'gt',
                                    },
                                    {
                                        key: 'date',
                                        value: endOfMonth.format('YYYY-MM-DD'),
                                        operator: 'lte',
                                    }
                                ]
                            }
                        ]
                    }

                    InquiryService.findAll(options, (response) => {
                        const body = response.data

                        this.inquiries[i] = body.total;

                        this.loaded++;
                    })

                    startOfMonth.subtract(1, 'month')
                    endOfMonth.subtract(1, 'month')
                }
            },

            capitalize(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
        },

        components: {
            Loader,
            Range
        }
    }
</script>
