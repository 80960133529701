<template>
    <div class="container-fluid">
        <card>
            <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center"><br>
                        <h3>Du har ikke indtastet kontaktinformationer på
                            et tilstrækkeligt antal kunder i dit økonomisystem</h3><br><br>
                        <img class="img-circle" src="http://placehold.it/170x170" /><br><br><br>
                </div>
                <div class="col-md-10 col-md-offset-1 text-center">
                    <p>
                        Du har indtastet telefonnummer eller e-mail på <strong>4/220 kunder</strong> i dit økonomisystem.
                        Indtaster du telefonfummer og e-mail på <strong>min. 80% af kunderne</strong> i dit økonomisystem, vil du på denne side kunne se dit salg for de kunder, der finder dig via din hjemmeside.
                    </p>
                    <p>
                        Opdater dit økonomisystem med kontaktinformation og tryk på knappen nedenfor:
                    </p><br>
                    <a href="#" class="btn btn-primary  btn-fill btn-lg">Jeg har indtastet kontaktinformationer <i class="fa fa-angle-right"></i></a>
                </div><br>
            </div>
        </card>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import Card from '@/app/shared/components/Card'

    export default {
        components: {
          Card
        }
    }
</script>
