<template>
    <div class="estimate-values">
        <p class="headline">{{$t('economy.estimatePage.valuesBox.headline')}}</p>
        <div class="description">
            <small class="text-muted">{{$t('economy.estimatePage.valuesBox.description')}}</small>
        </div>

        <hr>

        <div v-if="!isEditing">
            <div>
                <span>{{$t('economy.estimatePage.valuesBox.conversionRate')}} <a href="#" data-toggle="tooltip" :title="$t('economy.estimatePage.valuesBox.tooltip')"><i class="pe-7s-help1"></i></a></span>
                <span class="pull-right" v-if="hasLoaded">{{conversionRate}}% <a href="#"></a></span>
                <span class="mock pull-right" v-else>000</span>
            </div>

            <hr>

            <div>
                <span>{{$t('economy.estimatePage.valuesBox.turnoverPrOrder')}}</span>
                <span class="pull-right" v-if="hasLoaded">{{turnoverPrOrder | numberFormat}} kr.</span>
                <span class="mock pull-right" v-else>000</span>
            </div>

            <a v-if="hasLoaded" @click="isEditing = true" class="button button-edit-values">{{$t('economy.estimatePage.valuesBox.edit')}}</a>
            <a v-else class="button button-edit-values">{{$t('economy.estimatePage.valuesBox.edit')}}</a>
        </div>

        <div v-else :class="{error: hasError}">
            <div>
                <span>{{$t('economy.estimatePage.valuesBox.conversionRate')}} <a href="#" data-toggle="tooltip" :title="$t('economy.estimatePage.valuesBox.tooltip')"><i class="pe-7s-help1"></i></a></span>
                <span class="pull-right">
                    <div class="input-container">
                        <input type="text" class="form-control" v-model="internalConversionRate">
                        <span class="unit"> %</span>
                    </div>
                </span>
            </div>

            <hr>

            <div>
                <span>{{$t('economy.estimatePage.valuesBox.turnoverPrOrder')}}</span>
                <span class="pull-right">
                    <div class="input-container">
                        <input type="text" class="form-control" v-model="internalTurnoverPrOrder">
                        <span class="unit"> kr.</span>
                    </div>
                </span>
            </div>

            <a v-if="!isSending" @click="updateValues" class="button button-save-values">{{$t('economy.estimatePage.valuesBox.save')}}</a>
            <a v-else class="button button-save-values"><i class="fa fa-circle-o-notch fa-spin"></i></a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .estimate-values {
        .headline {
            margin-bottom: 0px;
        }

        .button {
            border-top: 1px solid #dedede;
            background-color: #f5f5f5;
            color: #4096ee;
            display: block;
            margin: 20px -30px -30px -30px;
            padding: 15px 0;
            text-align: center;
            cursor: pointer;

            &.button-edit-values{
                background-color: #f5f5f5;
            }

            &.button-save-values{
                color: white;
                background-color: #ef8f49;
            }
        }

        .input-container {
            display: table-row;

            input {
                display: table-cell;
                margin-top: -10px;
                width: 85px;
            }

            @media (min-width: 1200px) and (max-width: 1300px) {
                input {
                    width: 53px;
                }
            }

            .unit {
                text-indent: 5px;
                display: table-cell;
                width: 15px;
                text-align: left;
            }
        }

        .error {
            input {
                border-color: red;
            }
        }
    }
</style>

<style lang="scss">
    .tooltip {
        > .tooltip-inner {
            background: #222 !important;
            color: white !important;

            &:before, &:after {
                display: none !important;
            }
        }

        > .tooltip-arrow {
            display: none !important;
        }
    }
</style>

<script>
    const EstimateService = require('@/services/economy/EstimateService')

    export default {
        props: {
            conversionRate: {
                type: Number
            },
            turnoverPrOrder: {
                type: Number
            }
        },

        data() {
            return {
                isEditing: false,
                isSending: false,
                hasError: false,
                internalConversionRate: 0,
                internalTurnoverPrOrder: 0
            }
        },

        computed: {
            hasLoaded() {
                return (this.conversionRate >= 0 && this.turnoverPrOrder >= 0)
            }
        },

        watch: {
            conversionRate() {
                this.internalConversionRate = parseInt(this.conversionRate)
            },

            turnoverPrOrder() {
                this.internalTurnoverPrOrder = parseInt(this.turnoverPrOrder)
            },

            isEditing() {
                // Hack needed because this function is called before DOM is updated
                setTimeout(() => { this.initializeBootstrapTooltips() }, 100);
            }
        },

        mounted() {
            this.initializeBootstrapTooltips();
        },

        methods: {
            updateValues() {
                this.isSending = true;

                let data = {
                    conversionRate: this.internalConversionRate,
                    turnoverPrOrder: this.internalTurnoverPrOrder,
                }

                EstimateService.post(data, () => {
                    this.$emit('updateConversionRate', this.internalConversionRate)
                    this.$emit('updateTurnoverPrOrder', this.internalTurnoverPrOrder)

                    this.isEditing = false
                    this.isSending = false
                }, () => {
                    this.hasError = true
                    this.isSending = false
                })
            },

            initializeBootstrapTooltips() {
                jQuery('[data-toggle="tooltip"]').tooltip()
            }
        }
    }
</script>
