<template>
    <div class="top-customers">
        <ul class="list-full-width list-link">
            <li v-if="!hasLoaded" v-for="n in 3">
                <a class="mock">
                    Lorem ipsum amet est<br>
                    <small>Indtæg: 000.000</small>
                    <i class="fa fa-angle-right"></i>
                </a>
            </li>

            <li v-for="contact in contactsFromWebsite" v-if="hasLoaded">
                <router-link :to="{name: 'frontend.contacts.view', params: {id: contact.contact.id}}">
                    {{contact.name}}<br>
                    <small class="text-muted">{{$t('economy.topCustomers.turnover')}}: {{totalAmountSpend(contact)}}</small>
                    <i class="fa fa-angle-right"></i>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
    .list-full-width {
        list-style: none;
        margin: 0 -30px -30px;
        padding: 0;
    }

    .list-link {
        li {
            a {
                color: initial;
                padding: 15px 30px;
                display: block;
                border-top: 1px solid #e3e3e3;
                position: relative;

                i {
                    font-size: 24px;
                    position: absolute;
                    right: 30px;
                    top: 22px;
                    opacity: 0.5;
                }

                &:hover {
                     background: #f5f5f5;

                    i {
                        opacity: 1;
                    }
                }

                &.mock {
                    color: #ccc;
                }
            }
        }
    }
</style>

<script>
    const ContactService = require('@/services/orders/ContactService')

    export default {
      data() {
        return {
          contacts: [],
          hasLoaded: false,
        }
      },

      mounted() {
        this.load();
      },

      computed: {
        contactsFromWebsite() {
          return this.contacts.filter((orderContact) => {
            const contact = orderContact.contact

            if (!contact) {
              return
            }

            const sources = contact.sources.map(source => source.source)

            return (sources.includes('form') || sources.includes('call'))
          }).sort((orderContactA, orderContactB) => {
              const sumA = this.totalAmountSpend(orderContactA)
              const sumB = this.totalAmountSpend(orderContactB)

              return sumB - sumA
            }).slice(0, 3)
        }
      },

      methods: {
        load() {
          const options = {
            includes: ['orders', 'contact', 'contact.sources'],
            limit: 500,
            sort: [
              {
                key: 'sources.date',
                direction: 'DESC'
              }
            ]
          }

          ContactService.findAll(options, (response) => {
            const body = response.data;

            this.contacts = body.rows;

            this.hasLoaded = true;
          });
        },

        totalAmountSpend(orderContact) {
          return parseInt(orderContact.orders.reduce((sum, current) => {
                return sum + current.amount
              }, 0))
        }
      }
    }
</script>
