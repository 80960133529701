const BaseService = require('@/services/BaseService')

const namespace = 'economy/estimate'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

const post = (data, cb, errorCb) => {
    BaseService.post(namespace, data, cb, errorCb)
}

export {
    find,
    findAll,
    post
}
