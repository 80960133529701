import { render, staticRenderFns } from "./NewestCustomersList.vue?vue&type=template&id=77d9b180&scoped=true&"
import script from "./NewestCustomersList.vue?vue&type=script&lang=js&"
export * from "./NewestCustomersList.vue?vue&type=script&lang=js&"
import style0 from "./NewestCustomersList.vue?vue&type=style&index=0&id=77d9b180&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d9b180",
  null
  
)

export default component.exports