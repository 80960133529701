<template>
    <div class="economy-page container-fluid">
        <check-for-missing-integrations :integrations="['Freespee', 'Formstack']">
            <landing-page slot="no-integrations" image="/images/integrations/cta-messages/economy.png" :headline="$t('economy.onboarding.headline')" :description="$t('economy.onboarding.description')">

                <div v-if="! isFinland"
					 class="btn-group">
                    <button @click="tab = 'inquiries'" type="button" class="btn btn-primary">{{ $t('economy.onboarding.trackInquiries') }}</button>
                    <button @click="tab = 'economy'" type="button" class="btn btn-primary">{{ $t('economy.onboarding.connectEconomy') }}</button>
                </div>

                <br>
                <br>

                <call-and-form v-if="tab === 'inquiries'"></call-and-form>

                <div v-if="tab === 'economy'">
                    <integration logo="/images/onboarding/e-conomic.png" :border="true" styling="landscape" :headline="'E-conomic'" :description="$t('integrations.connectToOpn', {name: 'E-conomic'})">
                        <template v-if="!hasHealthyIntegration('Order: E-conomic')">
                            <div>
                                <a @click="connectEconomic()" class="btn btn-fill btn-primary">{{ $t('integrations.setUp') }}</a>
                            </div>
                        </template>

                        <template v-else>
                            <div class="connected connected-lg text-muted">
                                <span class="fa fa-check fa-fw"></span>
                                {{ $t('integrations.connected') }}
                            </div>

                            <br>
                            <br>

                            <div>
                                <remove-integration headline="E-conomic" name="Order: E-conomic"></remove-integration>
                            </div>
                        </template>
                    </integration>

                    <br>

                    <integration logo="/images/onboarding/dinero.png" :border="true" styling="landscape" :headline="'Dinero'" :description="$t('integrations.connectToOpn', {name: 'Dinero'})">
                        <template v-if="!hasHealthyIntegration('Order: Dinero')">
                            <div>
                                <router-link :to="{name: 'frontend.settings.integrations.dinero.setup.startPage'}" href="#" class="btn btn-fill btn-primary">{{ $t('integrations.setUp') }}</router-link>
                            </div>
                        </template>

                        <template v-else>
                            <div class="connected connected-lg text-muted">
                                <span class="fa fa-check fa-fw"></span>
                                {{ $t('integrations.connected') }}
                            </div>

                            <br>
                            <br>

                            <div>
                                <remove-integration headline="Dinero" name="Order: Dinero"></remove-integration>
                            </div>
                        </template>
                    </integration>
                </div>
            </landing-page>

            <div class="row">
                <div class="col-md-12">
                    <integration-banner></integration-banner>
                </div>
            </div>

            <div class="row row-lg-flex">
                <div class="col-md-12 col-lg-4 col-lg-flex col-lg-push-8">
                    <card class="tour-step-2">
                        <estimate-values :conversionRate="conversionRate" :turnoverPrOrder="turnoverPrOrder" @updateConversionRate="updateConversionRate" @updateTurnoverPrOrder="updateTurnoverPrOrder"></estimate-values>
                    </card>
                </div>

                <div class="col-md-6 col-lg-4 col-lg-pull-4 col-lg-flex">
                    <card :center="true">
                        <info-box icon="pe-7s-piggy" :number="totalEstimatedTurnover" :unit="$t('economy.estimatePage.totalEstimate')"></info-box>
                    </card>
                </div>

                <div class="col-md-6 col-lg-4 col-lg-pull-4 col-lg-flex">
                    <card :center="true">
                        <info-box icon="pe-7s-comment" :number="numberOfInquiries" :unit="$t('economy.estimatePage.numberOfInquiries')"></info-box>
                    </card>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <card class="tour-step-1" :headline="$t('economy.estimatePage.turnoverChart.headline')" :description="$t('economy.estimatePage.turnoverChart.description')">
                        <estimated-turnover-chart @hasLoaded="startTourWhenReady()" :conversionRate="conversionRate" :turnoverPrOrder="turnoverPrOrder"></estimated-turnover-chart>
                    </card>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <card class="tour-step-3" icon="pe-7s-calculator" :headline="$t('economy.estimatePage.estimateInfo.headline')" :description="$t('economy.estimatePage.estimateInfo.description')">
                        <estimate-info></estimate-info>
                    </card>
                </div>
            </div>
        </check-for-missing-integrations>
    </div>
</template>

<style lang="scss" scoped>
    .economy-page {
        .integrations-container-muted {
            opacity: 0.65;
        }

        h4 {
            font-size: 15px;
            margin: 0;
            padding: 0;
        }

        .hr {
            text-align: center;
            overflow: hidden;
            margin-bottom: 15px;

            span {
                color: #888;
                background: #f5f5f5;
                position: relative;
                padding: 15px;

                &::before {
                    content: '' !important;
                    position: absolute !important;
                    border-bottom: 1px solid #e4e4e4 !important;
                    top: 50% !important;
                    right: 100% !important;
                    width: 5000px !important;
                }

                &::after {
                    content: '' !important;
                    position: absolute !important;
                    border-bottom: 1px solid #e4e4e4 !important;
                    top: 50% !important;
                    left: 100% !important;
                    width: 5000px !important;
                }
            }
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import IntegrationBanner from '@/app/economy/components/IntegrationBanner'
    import InfoBox from '@/app/layout/components/InfoBox'
    import EstimateValues from '@/app/economy/components/EstimateValues'
    import EstimateInfo from '@/app/economy/components/EstimateInfo'
    import EstimatedTurnoverChart from '@/app/economy/components/EstimatedTurnoverChart'
    import Banner from '@/app/onboarding/components/Banner'
    import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
    import LandingPage from '@/app/onboarding/components/LandingPage'
    import CallAndForm from '@/app/onboarding/components/integrations/CallAndForm'
    import Integration from '@/app/onboarding/components/integrations/Integration'

    import tourMixin from '@/mixins/tours/tourMixin'
    import hasIntegration from '@/mixins/integrations/hasIntegration'
    import connectEconomic from '@/mixins/integrations/connectEconomic'

    const InquiryService = require('@/services/inquiries/InquiryService')
    const EstimateService = require('@/services/economy/EstimateService')

    import {mapGetters} from 'vuex'

    export default {
        mixins: [tourMixin, hasIntegration, connectEconomic],

        data() {
            return {
                numberOfInquiries: 0,
                conversionRate: -1,
                turnoverPrOrder: -1,
                tab: 'inquiries'
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer'
          }),

			isFinland() {
				return !! this.customer && !! this.customer.country && this.customer.country.code === 'FI'
			},

            industry() {
                if (!this.customer) {
                    return
                }

                return this.customer.industry
            },

            integrations() {
                if (!this.customer) {
                    return
                }

                return this.customer.integrations
            },

            totalEstimatedTurnover() {
                return Math.round(this.numberOfInquiries * (this.conversionRate / 100) * this.turnoverPrOrder)
            },
        },

        beforeMount() {
            if (
                this.hasHealthyIntegration('Order: E-conomic') ||
                this.hasHealthyIntegration('Order: Dinero')
            ) {
                this.redirectToIntegrationPage()
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                const options = {
                    limit: 1,
                }

                InquiryService.findAll(options, (response) => {
                    const body = response.data

                    this.numberOfInquiries = body.total
                })

                const estimateOptions = {
                    includes: ['customer', 'industry'],
                    filter_groups: [
                        {
                            or: true,
                            filters: [
                                {
                                    key: 'industry',
                                    operator: 'eq',
                                    value: (this.industry) ? this.industry.id : 'NULL',
                                },
                                {
                                    key: 'customer',
                                    value: (this.customer) ? this.customer.id : 'NULL',
                                    operator: 'eq'
                                },
                            ]
                        }
                    ]
                }

                EstimateService.findAll(estimateOptions, (response) => {
                    const body = response.data
                    const estimates = body.rows

                    let estimate = this.mostImportantEstimate(estimates)

                    if (!estimate) {
                        this.conversionRate = 20
                        this.turnoverPrOrder = 1500
                        return
                    }

                    this.conversionRate = parseInt(estimate.conversionRate)
                    this.turnoverPrOrder = parseInt(estimate.turnoverPrOrder)
                });
            },

            mostImportantEstimate(estimates) {
                if (!estimates || estimates.length === 0) {
                    return
                }

                let result = estimates[0]

                estimates.forEach((estimate) => {
                    if (estimate.customer) {
                        result = estimate
                    }
                })

                return result
            },

            updateConversionRate(conversionRate) {
                this.conversionRate = conversionRate
            },

            updateTurnoverPrOrder(turnoverPrOrder) {
                this.turnoverPrOrder = turnoverPrOrder
            },

            redirectToIntegrationPage() {
                this.$router.push({
                    name: 'frontend.reporting.economy.integration'
                })
            },

            startTourWhenReady() {
                let options = {
                    steps: [
                        {
                            element: '.tour-step-1',
                            intro: '<h5>Estimaeret omsætning</h5><p>Se estimeret omsætning fordelt pr. måned.</p>',
                            position: 'top',
                        },
                        {
                            element: '.tour-step-2',
                            intro: '<h5>Anvendte værdier til Estimat</h5><p>Her kan du opdatere værdier i estimatet, så det er tilpasset din virksomhed.</p>',
                            position: 'top',
                        },
                        {
                            element: '.tour-step-3',
                            intro: '<h5>Estimatet</h5><p>Her kan de se hvordan estimatet, bliver beregnet.</p>',
                            position: 'top',
                        },
                    ]
                }

                if (this.isOnboarding) {
                    options.steps.push({
                        element: '.economy-estimate',
                        intro: '<h5>Fortsæt det gode arbejde</h5><p>Du har nu opsat din første integration i OP\'N. Klik på integrationssiden for at opsætte den næste.</p>',
                    })
                }

                this.startTour('economy-estimate', options)
            }
        },

        components: {
            Card,
            IntegrationBanner,
            InfoBox,
            EstimateValues,
            EstimateInfo,
            EstimatedTurnoverChart,
            Banner,
            CheckForMissingIntegrations,
            LandingPage,
            CallAndForm,
            Integration,
        }
    }
</script>

